import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Homepage from "./layout/homepage";
import Workspace from "./layout/workspace";
import Projects from "./layout/projects";
import Academy from "./layout/academy";
import Labs from "./layout/labs";

const App: React.FC = () => {
  return (
    <Router>
      <Route exact path="/workspaces" component={Workspace} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/labs" component={Labs} />
      <Route exact path="/academy" component={Academy} />
      <Route path="/" component={Homepage} />
    </Router>
  );
};

export default App;
